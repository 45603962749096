<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" label-width="77px" class="ele-form-search d-flexspabet"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div>
					<el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
						v-if="permission.includes('sys:wantAsk:add')">添加答疑
					</el-button>
				</div>
				<div class="d-flex">
					<el-form-item label="类型:" label-width="80px">
						<el-select v-model="table.where.side_type" placeholder="请选择" class="input150" clearable>
							<el-option label="用户版" :value="1" />
							<el-option label="司机版" :value="2" />
							<el-option label="跑腿版" :value="3" />
							<el-option label="商家版-超市" :value="4" />
							<el-option label="商家版-叫餐" :value="5" />
						</el-select>
					</el-form-item>
					<div class="ml-20">
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon">搜索
						</el-button>
						<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
					</div>
				</div>
			</el-form>

			<!--<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row>-->
			<ele-data-table id="out-table" ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="side_type" label="显示版" show-overflow-tooltip min-width="200">
						<template slot-scope="{row}">
							<span>{{row.side_type==1?'可蚁点用户版':row.side_type==2?'可蚁点司机版':row.side_type==3?'可蚁点跑腿版':
                 row.side_type==4?'可蚁点商家版-超市':'可蚁点商家版-叫餐'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="title" label="标题" show-overflow-tooltip min-width="50" />
					<el-table-column prop="content_" label="内容" show-overflow-tooltip min-width="250" />
					<el-table-column  label="更新时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="操作" width="180px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:wantAsk:edit')">编辑</el-link>
							<el-link icon="el-icon-delete" type="danger" :underline="false" @click="remove(row)"
								v-if="permission.includes('sys:wantAsk:delete')">删除</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'修改猜你想问':'添加猜你想问'" :visible.sync="showEdit" @closed="editForm={}"
			:destroy-on-close="true" :lock-scroll="false">

			<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="82px">
				<el-card shadow="never">
					<el-form-item label="显示版:" prop="side_type">
						<el-select v-model="editForm.side_type" placeholder="请选择" clearable class="input216"
							:disabled="editForm.id?true:false">
							<el-option label="可蚁点用户版" :value="1" />
							<el-option label="可蚁点司机版" :value="2" />
							<el-option label="可蚁点跑腿版" :value="3" />
							<el-option label="可蚁点商家版-超市" :value="4" />
							<el-option label="可蚁点商家版-叫餐" :value="5" />
							<!-- <el-option label="可蚁点用户版" :value="5" />
							<el-option label="可蚁点司机版" :value="6" />
							<el-option label="可蚁点跑腿版" :value="7" />
							<el-option label="可蚁点商家版" :value="8" /> -->
						</el-select>
					</el-form-item>
					<el-form-item label="标题:" prop="title">
						<el-input v-model="editForm.title"></el-input>
					</el-form-item>
					<!-- 富文本编辑器 -->
					<tinymce-editor v-model="editForm.content" :init="editContent" />
				</el-card>
			</el-form>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<Footer></Footer>
	</div>
</template>

<script>
	import TinymceEditor from '@/components/TinymceEditor'
	import {
		mapGetters
	} from "vuex";
	import Footer from "../../../components/footer"
	export default {
		name: "SysNotice",
		components: {
			TinymceEditor,
			Footer
		},
		data() {
			return {
				table: {
					url: '/customer/want_ask_list',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {
					source: 1,
					status: 1,
					is_top: 2
				}, // 表单数据
				editRules: { // 表单验证规则
					title: [{
						required: true,
						message: '请输入标题',
						trigger: "blur"
					}],
					side_type: [{
						required: true,
						message: '请选择显示版',
						trigger: "change"
					}]
				},
				// 自定义文件上传(这里使用把选择的文件转成blob演示)
				file_picker_callback: (callback, value, meta) => {
					let input = document.createElement('input');
					input.setAttribute('type', 'file');
					// 设定文件可选类型
					if (meta.filetype === 'image') {
						input.setAttribute('accept', 'image/*');
					} else if (meta.filetype === 'media') {
						input.setAttribute('accept', 'video/*');
					}
					input.onchange = () => {
						let file = input.files[0];
						let reader = new FileReader();
						reader.onload = (e) => {
							let blob = new Blob([e.target.result], {
								type: file.type
							});
							callback(URL.createObjectURL(blob));
						};
						reader.readAsArrayBuffer(file);
					}
					input.click();
				}
			}
		},
		onload(){
			
		},
		computed: {
			...mapGetters(["permission"]),

			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css'
				};
			}
		},
		mounted() {},
		methods: {
			sb(row){
				//console.log(row)
			},
			/* 显示编辑 */
			edit(row) {
				//console.log(row);
				this.editForm = row;
				const loading = this.$loading({ lock: true });
				if(this.editForm){
					loading.close();
					this.showEdit = true;
				}
			},
			/* 保存编辑 */
			save() {
				this.editForm.notice_type = 2
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/customer/want_save', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/* 删除 */
			remove(row) {
				this.$confirm('确认删除此答疑?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/customer/want_delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},

		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}
</style>
